<template>
    <div class="tips fixed">
        <div v-html="tips" class="content"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tips: ''
        };
    },
    mounted() {
        console.log('summary', this.$route.params.summary);
        this.tips = this.$route.params.summary;
    }
};
</script>
<style lang="scss" scoped>
.tips {
    background: #f5f6f9;
    height: 100%;
    width: 100%;
    position: absolute;
    padding-top: 0.3rem;
    overflow-y: scroll;
    .content {
        padding: 0.3rem;
        padding-bottom: 0.6rem;
        margin: 0 0.3rem 0.3rem;
        line-height: 0.7rem;
        word-wrap: break-word;
        font-size: 0.28rem;
        white-space: pre-line;
        background: #fff;
        color: #333333;
        border-radius: 0.16rem;
    }
}
</style>
